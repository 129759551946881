<template>
  <div>
    <b-form-group
      label="Firma"
      label-for="company"
    >
      <b-form-input
        id="company"
        v-model="itemData.translate.company"
        placeholder="Firma"
      />
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.company }}
        </div>
      </b-alert>
    </b-form-group>
    <b-form-group
      label="Adı Soyadı"
      label-for="name"
    >
      <b-form-input
        id="name"
        v-model="itemData.translate.name"
        placeholder="Adı Soyadı"
      />
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.name }}
        </div>
      </b-alert>
    </b-form-group>
    <b-form-group
      label="Başlık"
      label-for="title"
    >
      <b-form-input
        id="title"
        v-model="itemData.translate.title"
        placeholder="Başlık"
      />
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.title }}
        </div>
      </b-alert>
    </b-form-group>
    <b-form-group
      label="İçerik"
      label-for="content"
    >
      <quill-editor
        id="content"
        v-model="itemData.translate.content"
        class="bg-white"
        :options="editorOption"
      >
        <div
          id="content_toolbar"
          slot="toolbar"
        >
          <editor-toolbar />
        </div>
      </quill-editor>
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          <div v-html="itemData.item.content" />
        </div>
      </b-alert>
    </b-form-group>
    <b-button
      variant="primary"
      size="sm"
      @click="saveAction"
    >
      <FeatherIcon icon="SaveIcon" />
      Kaydet
    </b-button>
  </div>
</template>
<script>
import {
  BFormGroup, BFormInput, BAlert, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import EditorToolbar from '@/layouts/components/common/EditorToolbar.vue'

export default {
  name: 'Testimonails',
  components: {
    EditorToolbar,
    BFormGroup,
    BFormInput,
    BAlert,
    BButton,
    quillEditor,
  },
  props: {
    saveAction: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#content_toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['translate/dataItem']
    },
  },
}
</script>
