<template>
  <div>
    <b-form-group
      label="Başlık"
      label-for="title"
    >
      <b-form-input
        id="title"
        v-model="itemData.translate.title"
        placeholder="Başlık"
      />
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.title }}
        </div>
      </b-alert>
    </b-form-group>
    <b-button
      variant="primary"
      size="sm"
      @click="saveAction"
    >
      <FeatherIcon icon="SaveIcon" />
      Kaydet
    </b-button>
  </div>
</template>
<script>
import {
  BFormGroup, BFormInput, BAlert, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Sectors',
  components: {
    BFormGroup,
    BFormInput,
    BAlert,
    BButton,
  },
  props: {
    saveAction: {
      type: Function,
      require: true,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['translate/dataItem']
    },
  },
}
</script>
